import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import MiddleSection from "./containers/middleSection";
import Roadmap from "./containers/Roadmap";
import Footer from "./containers/Footer";
import Navbar from "./containers/Navbar";
import "animate.css/animate.min.css";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./compoents/ScrollToTop";
function App() {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div className="App">
      <div className="overee">
        <Navbar data-aos="fade-up" />

        <div className="middle">
          <MiddleSection />
        </div>
      </div>
      <Roadmap />
      <Footer />
      <ScrollToTop />
    </div>
  );
}

export default App;
