import React, { useState, useEffect } from "react";
const ScrollToTop = () => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 400) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <button
      onClick={goToTop}
      type="button"
      className={`${
        showTopBtn ? "opacity-100" : "opacity-0"
      } fixed z-50 p-2 h-14 w-14 flex justify-center items-center border-2 border-[#371674] hover:bg-[#fdc91a]/80 right-8 bottom-8 rounded-full text-3xl transition-all duration-300 bg-[#fdc91a] cursor-pointer`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        className="inline w-5 h-5 fill-[#371674]"
      >
        <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
      </svg>
    </button>
  );
};
export default ScrollToTop;
