import React from "react";
import "./Navbar.css";
// import Game from '../Game/Game';

import TwitterIcon from '@mui/icons-material/Twitter';
import TelegramIcon from '@mui/icons-material/Telegram';
import TopSection from "./TopSection";
import Logo from "../assest/images/logo.png";
import { useState } from "react";

// import discort from "./discort.png"

function Navbar() {
  const [show, setShow] = useState(false);
  return (
    <div className="head">
      <nav className="navbar navbar-expand-xl navbar-dark lg:p-4">
        <div
          className="container-fluid"
          data-aos="zoom-out-down"
          ease-in-sine="ease-in"
        >
          <a className="navbar-brand" href="/">
            <img src={Logo} alt="Solerium" title="Solerium" className="logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynavbar"
            onClick={() => setShow(!show)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className={`collapse navbar-collapse ${show ? "show" : ""}`}>
            <ul className="navbar-nav ms-auto me-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#tokenomic">
                  Tokennomics
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#roadmap">
                  Roadmap
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://www.dextools.io/app/en/solana/pair-explorer/FJ1DHFFHvW4UFNELFu6VonuAvaMK3Ch58LUMqsNqLnwk" target="_blank">
                  Dextools
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://t.me/MonkuOnSol">
                  Telegram
                </a>
          
              </li>
            </ul>
            <form className=" d-flex">
              <div className="navbar-icon">
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TelegramIcon />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    className="MuiSvgIcon-root"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path d="M180.5,74.262C80.813,74.262,0,155.633,0,256S80.819,437.738,180.5,437.738,361,356.373,361,256,280.191,74.262,180.5,74.262Zm288.25,10.646c-49.845,0-90.245,76.619-90.245,171.095s40.406,171.1,90.251,171.1,90.251-76.619,90.251-171.1H559C559,161.5,518.6,84.908,468.752,84.908Zm139.506,17.821c-17.526,0-31.735,68.628-31.735,153.274s14.2,153.274,31.735,153.274S640,340.631,640,256C640,171.351,625.785,102.729,608.258,102.729Z" />
                  </svg>
                </a>
              </div>
              <a
                href="https://www.dextools.io/app/en/solana/pair-explorer/GHB1Ccpy2yJP9PGQAxTqcjwJ4RgZDK5CtcATpacNCWPc?t=1713175759731"
                className="lg:ml-8 button BuyBTN"
              >
                <span className="slrm">BUY $MONKU NOW</span>
              </a>
            </form>
          </div>
        </div>
      </nav>
      <div>
        <TopSection />
      </div>
    </div>
  );
}

export default Navbar;
