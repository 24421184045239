import React from "react";
import "./Tokenomic.css";
import Ninja from "../assest/images/ninja.png";
import Diamond from "../assest/images/diamondIcon.png";
import Coin from "../assest/images/iCoin.png";
import CatIcon from "../assest/images/icon.png";
import Envelop from "../assest/images/envelop.png";
import SquareDiamond from "../assest/images/square-diamond.png";

function Tokenomic() {
  return (
    <div id="tokenomic" className="scroll-mt-64">
      <div
        className="container"
        data-aos="zoom-out"
        data-aos-easing="ease-in-sine"
      >
        <div className="row">
          <div className="col-md-12">
            <div className="tokenomic-heading ">
              <h2>
              IF YOU'RE HERE FOR <span>MEME MAGIC</span> THEN YOU'RE READY FOR <span>MONKU!</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="pb-24 mt-5 row">
          <div className="flex items-center justify-center col-xl-6 col-lg-12 col-md-12">
            <div
              className="cat-laptop"
              data-aos="fade-right"
              ease-in-sine="ease-in"
            >
              <img src={Ninja} className="Ninja img-fluid max-h-96" alt="" />
            </div>
          </div>

          <div className="col-xl-6 col-lg-12 col-md-12">
            <div
              className="tokenomic-list"
              data-aos="fade-left"
              ease-in-sine="ease-in"
            >
              <div className="tokenBox-head">
                <h3 className="">TOKENOMICS</h3>
              </div>
              <div className="tokenomic-box">
                <div className="tokenomic-textBox">
                  <img src={Diamond} alt="" />
                  <p>
                    Total Supply: <span>1 Billion</span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={Coin} alt="" />
                  <p>
                    Type: <span>MemeCoin &amp; SOL</span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={CatIcon} alt="" />
                  <p>
                    Token Symbol: <span>Monku</span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={Envelop} alt="" />
                  <p className="truncate">
                    Contract ID:{" "}
                    <span>
                      <a href="https://solscan.io/account/FJ1DHFFHvW4UFNELFu6VonuAvaMK3Ch58LUMqsNqLnwk">
                      FJ1DHFFHvW4UFNELFu6VonuAvaMK3Ch58LUMqsNqLnwk
                      </a>
                    </span>
                  </p>
                </div>
                <div className="mt-2 tokenomic-textBox">
                  <img src={SquareDiamond} alt="" />
                  <p>
                    Network: <span>Solana</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokenomic;
