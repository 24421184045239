import React from "react";
import "./middleSection.css";
import MiddleCard from "../compoents/MiddleSectionComonents/MiddleCard";
import Stats from "../compoents/stats/Stats";
import Tokenomic from "../containers/Tokenomic";

const MiddleSection = () => {
  const presaleLink = "https://www.coingecko.com/en/coins/monku"; // Tutaj wstaw link do presale, na przykład "https://pinksale.finance"

  return (
    <>
      <div className="relative overflow-hidden MainSection">
        <div className="img2"></div>
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div
              className="col-lg-5 col-md-6 col-sm-12 col-xs-2 MiddleCard "
              data-aos="fade-right"
              ease-in-sine="ease-in"
              data-aos-delay="100"
              data-aos-duration="600"
            >
              {/* Link otaczający komponent MiddleCard */}
              <a href={presaleLink} target="_blank" rel="noopener noreferrer">
                <MiddleCard
                  title="JOIN THE MONKU Community"
                  dec="Don't miss the chance to be an early adopter! Buy Monku tokens with SOL and join the meme craze!"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Other components can be left intact or removed as needed */}

      <div>
        <Stats />
      </div>

      <div>
        <Tokenomic />
      </div>
    </>
  );
};

export default MiddleSection;
