import React from "react";
import "./MiddleCard.css";

const MiddleCard = (props) => {
  return (
    <div className="middlecard mt-5">
      <div className="card mx-auto">
        <div className="cardhead">
          <h4>{props.title}</h4>
        </div>
        <div className="cardmiddle mb-2 mt-2 text-center text-white">
          {props.dec}
        </div>
        <ul className="mb-5">
          <li className="plan me-5 mb-5 p-1">
            <a href="https://www.dextools.io/app/en/solana/pair-explorer/GHB1Ccpy2yJP9PGQAxTqcjwJ4RgZDK5CtcATpacNCWPc?t=1713175759731" className="text-center w-full">
              Join Monku
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MiddleCard;
